module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-M8CLJRLF","includeInDevelopment":true,"defaultDataLayer":{"type":"function","value":"()=>({originalLocation:`${document.location.protocol}//${document.location.hostname}${document.location.pathname}${document.location.search}`,accesoApp:!1})"},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"i18n","languages":["es","eu","ca"],"redirect":false,"generateDefaultLanguagePage":false,"defaultLanguage":"es","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":false,"saveMissing":false},"pages":[{"matchPath":"/","getLanguageFromPath":true},{"matchPath":{},"excludeLanguages":["eu","ca"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(max-width: 426px)","sm":"(max-width: 520px)","md":"(max-width: 768px)","mobile":"(max-width: 520px)","tablet":"(min-width: 521px) and (max-width: 768px)","laptop":"(min-width: 769px) and (max-width: 1367px)","desktop":"(min-width: 769px)"}},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
